
import React, {Component, useState} from 'react';
import moment from 'moment';
import {FormInputContainer, NavigationMenuItem, NavigationMenuSubItem, Row} from './Common.js';
import t from './i18n';
import { pweb , pwebs, Loader , Utils, Route, Link, FormDashline, FormInputRadio,
    FormInputSelect, FormInputSelectOption, FormInputText, FormInputDate,
    FormButtons, FormInputImage , BoxContainer, SweetAlert } 
    from './Common.js';
import './PrivateArea.css';
import { access } from 'fs';
import { OrdersUtil } from './Order.js';

class AdminUsersActionBannersEdit extends Component {

    constructor(props){
        super( props );

        this.state = {
            data: false,
            saving: false,
            id: Utils.getUrlParam( -1 , -1 )
        };

        this.state['loading'] = true;

        pweb().fetch( 'admin' , 'banners' , 'list' , msg => {

            this.setState({
                loading: false,});

            if( !msg.data )
                return;

            let banner_data = {
                des_nome: 'Nuovo banner',
                cda_tipo: 'banner',
                dat_inizio: moment().toDate(),
                dat_fine: moment().add( '1' ,'day' ).toDate(),
            };

            msg.data.map( banner => {
                if( banner._id == this.state.id )
                    banner_data = banner
            });

            this.setState({
                ...banner_data,
                loading: false,});
        });

        this.onSave = this.onSave.bind(this);
        this.onUpload = this.onUpload.bind(this);
        
    }

    onSave(se){
        
        se.preventDefault();
        
        this.setState({saving: true});

        pweb().fetch( 'admin' , 'banners' , 'save' , this.state , msg => {
            if( msg.status )
                Route.pushLocation( '/system/banners_list' );
        } );
    }

    onUpload( file, cbFinish ){
        pweb().fetch( 'admin', 'banners', 'uploadPopup' , this.state.id || 0 , { image: file } , data => {
            this.setState({des_image_path: data.data});
            cbFinish();
        });
    }

    render(){
        if( this.state.saving || this.state.loading )
            return <Loader />;

        return <BoxContainer title={t("Modifica banner")}>
            <form className="" onSubmit={this.onSave}>

                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <FormInputText label={t('Nome')} value={this.state.des_nome} 
                            onChange={se=>{this.setState({des_nome:se.target.value})}} />
                    </div>
                </div>

                <Row>
                    <div className="col-lg-6">
                        <FormInputSelect label={t('Tipo')} value={this.state.cda_tipo}
                            style={{minWidth:'150px'}}
                            onChange={se=>{this.setState({cda_tipo: se})}} >

                            <FormInputSelectOption label={t('Banner')} value="banner" />
                            <FormInputSelectOption label={t('Popup')} value="modal" />

                        </FormInputSelect>
                    </div>
                </Row>

                {this.state.cda_tipo == 'banner' && <React.Fragment>

                    <Row>
                        <div className="col-lg-6">
                            <FormInputText label={t('Titolo')} value={this.state.des_titolo} 
                                onChange={se=>{this.setState({des_titolo:se.target.value.substr(0,99)})}} />
                        </div>
                    </Row>

                    <Row>
                        <div className="col-lg-6">
                            <FormInputText label={t('Corpo')} value={this.state.des_corpo} 
                                onChange={se=>{this.setState({des_corpo:se.target.value.substr(0,99)})}} />
                        </div>
                    </Row>
                    
                </React.Fragment>}

                {this.state.cda_tipo == 'modal' && <React.Fragment>

                    <Row>
                        <div className="col-md-6 col-md-offset-3" style={{margin: '20px'}}>
                            <FormInputImage src={this.state.des_image_path} 
                                noImageLabel="Nessuna immagine"
                                onUpload={this.onUpload} />
                        </div>
                    </Row>

                </React.Fragment>}

            
                <Row>
                    <div className="col-lg-6">
                        <FormInputDate 
                            label= "Data inizio"
                            value={this.state.dat_inizio}
                            onChange={se => { this.setState({dat_inizio: se})}} />
                    </div>
                </Row>

                <Row>
                    <div className="col-lg-6">
                        <FormInputDate 
                            label= "Data Fine"
                            value={this.state.dat_fine}
                            onChange={se => { this.setState({dat_fine: se})}} />
                    </div>
                </Row>
                
                <Row>
                    <div className="col-sm-10 col-sm-offset-2" style={{marginTop: '10px'}}>
                        <FormInputRadio label="Attivo" checked={this.state.flg_abil > 0}
                            onClick={se=>{ this.setState({flg_abil: this.state.flg_abil > 0 ? 0 : 1})}} />
                    </div>
                </Row>

                <FormDashline />

                <div className="row">
                    <FormButtons onSave={this.onSave} onCancel={se=>{Route.pushLocation('/system/banners_list')}} />
                </div>
                    
            </form>
        </BoxContainer>;
    }
}

class AdminUsersActionBannersList extends Component {

    constructor(props){
        super( props );

        this.state = {
            data: false
        };

        this.onBannerClick = this.onBannerClick.bind(this);
    }

    componentDidMount(){
        pweb().fetch( 'admin' , 'banners' , 'list' , msg => {
            if( msg.data )
                this.setState({data: msg.data});
        });
    }

    onBannerClick( banner ){
        Route.pushLocation( '/system/banners_edit/' + banner._id );
    }

    render(){
        if( !this.state.data )
            return <Loader />;

        return <BoxContainer title="Banner attivi">
            <div>
                <a href="/system/banners_edit" className="btn btn-primary"><i className="fa fa-plus"></i> Nuovo</a>
            </div>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Durata</th>
                        <th>Stato</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.data.map( banner => {

                        let state = <span className="label label-success">Attivo</span>;

                        if( moment( banner.dat_fine ).isBefore( moment() ) )
                            state = <span className="label label-danger">Scaduto</span>;

                        if( !banner.flg_abil )
                            state = <span className="label label-danger">Non attivo</span>;

                        return <tr style={{cursor: 'pointer'}} key={0} onClick={se=>{this.onBannerClick(banner)}}>
                            <td>{banner.des_nome}</td>  
                            <td>{moment(banner.dat_inizio).format('DD/MM')} -> {moment(banner.dat_fine).format('DD/MM')}</td>
                            <td>{state}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </BoxContainer>;
    }
}

class AdminUsersBanned extends Component {
    constructor(props){
        super( props );

        this.state = {
            data: false,
            page: 0,
            perPage: 15,
            new: ''
        };

        this.reload();

        this.onNew = this.onNew.bind(this);
    }

    reload(){
        this.setState({data: false});

        pweb().fetch( 'admin' , 'users' , 'banned' , msg => {
            if( msg )
                this.setState({data: msg});
        });
    }

    onNew(se){
        se.preventDefault();

        pweb().fetch( 'admin' , 'users', 'ban' , this.state.new , msg => {
            this.setState({new:''});
            this.reload();
        });

    }
    
    onDelete( username ){

        pweb().fetch( 'admin' , 'users' , 'unban' , username , msg => {
            this.reload();
        });

    }

    render(){

        let pageItems = [];
        let pages = 1;

        if( this.state.data ){

            let data = this.state.data.concat([]);

            if( this.state.search )
                data = data.filter( item => {
                    return item.cda_ana.toLowerCase().indexOf( this.state.search.toLowerCase() ) >= 0 ||
                      item.rag_soc.toLowerCase().indexOf( this.state.search.toLowerCase() ) >= 0
                });

            pages = Math.ceil( data.length / this.state.perPage );
            pageItems = data.slice( this.state.page * this.state.perPage , (this.state.page+1) * this.state.perPage );
        }

        return <BoxContainer title={t('Report utenti bannati')}>
            { !this.state.data && <Loader />}
            { this.state.data && <div>

                <div className="row">
                    <div className="col-md-6">
                        <FormInputText label={t('Cerca')} value={this.state.search}
                                       direction={FormInputContainer.HORIZONTAL}
                                       onChange={se=>{this.setState({search:se.target.value, page: 0})}} />
                    </div>
                    <div className="col-md-6"></div>
                </div>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Utente</th>
                            <th>Ragione sociale</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {pageItems.map( user => {
                            return <tr key={user.cda_ana}>
                                <td>{user.cda_ana}</td>
                                <td>{user.rag_soc}</td>
                                <td>
                                    <a onClick={se=>{se.preventDefault(); this.onDelete(user.cda_ana)}} href="#"
                                        className="btn btn-default"><i className="fa fa-trash"></i></a>
                                </td>
                            </tr>
                        })}

                        <tr>
                            <td>
                                <input className="form-control" onChange={se=>{this.setState({new: se.target.value})}} 
                                    value={this.state.new} />
                            </td>
                            <td>
                                <a onClick={this.onNew} href="#" className="btn btn-default">Aggiungi</a>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className={"row"}>
                    <div className={"col-md-4"}>
                        <button className="btn btn-sm " onClick={se=>{
                            se.preventDefault();
                            if( this.state.page > 0 )
                                this.setState({page: this.state.page-1});
                        }}>Indietro</button>
                    </div>
                    <div className={"col-md-4 text-center"}>
                        <span>Pagina {this.state.page+1} / {pages}</span>
                    </div>
                    <div className={"col-md-4 text-right"}>
                        <button className="btn btn-sm " onClick={se=>{
                            se.preventDefault();
                            if( this.state.page < pages-1 )
                                this.setState({page: this.state.page+1});
                        }}>Avanti</button>

                    </div>
                </div>

            </div> }
        </BoxContainer>;
    }
}

class AdminUsersActionReport extends Component {
    constructor(props){
        super( props );

        this.state = {
            data: false
        };

        pweb().fetch( 'admin' , 'users', 'action_report' , msg => {
            if( msg )
                this.setState({data: msg});
        });
    }

    render(){
        return <BoxContainer title={t('Report ultime azioni')}>
            { !this.state.data && <Loader />}
            { this.state.data && <div>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Utente</th>
                            <th>Azione</th>
                            <th>Data</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.data.map( access => {
                            return <tr key={access.created}>
                                <td>{access.user_id}</td>
                                <td>{access.action}</td>
                                <td>{moment(access.created).format('DD/MM/YY HH:mm:ss')}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div> }
        </BoxContainer>;
    }
}

class AdminUsersLoginReport extends Component {

    constructor(props){
        super( props );

        this.state = {
            data: false
        };

        pweb().fetch( 'admin' , 'users' ,'login_report' , msg => {
            if( msg )
                this.setState({data: msg});
        });
    }

    render(){
        return <BoxContainer title={t('Report accessi')}>
            { !this.state.data && <Loader />}
            { this.state.data && <div>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Utente</th>
                            <th>Tipo</th>
                            <th>Data accesso</th>
                            <th>Ip</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.data.map( access => {
                            let style = {display: 'table-row'};

                            let action = access.action;
                            if( action == 'login' ) action = 'Nuovo';
                            if( action == 'check_key' ) action = 'Accesso';
                            if( access.ip_address )
                                if( access.ip_address.match( /(::1|10\.130\.1\.[0-9]+)/i ) )
                                    style.color = 'lightgrey';

                            return <tr key={access.dat_created} style={style}>
                                <td>{access.user_id}</td>
                                <td>{action}</td>
                                <td>{moment(access.dat_created).format('DD/MM/YY HH:mm:ss')}</td>
                                <td>{access.ip_address}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div> }
        </BoxContainer>;
    }
}

class NfOrderCompare extends Component {

    constructor(props){
        super(props);

        this.state = {
            loading: true
        };

        this.updateData();
    }

    updateData(){
        pweb().fetch( 'admin' , 'nf_order_compare' , msg => {
            if( msg.data ){
                let data = msg.data;
                
                this.setState({ data: data, loading: false});
            }
        });
    }

    render(){

        if( this.state.loading )
            return <Loader />;

        return <BoxContainer title={t('NF/PR Comparazione')}>
            { !this.state.data && <Loader />}
            { this.state.data && <div>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th># Ordine</th>
                            <th>Data </th>
                            <th>Cliente</th>
                            <th>Pagamento</th>
                            <th>Stato NF</th>
                            <th>Stato PRI</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.data.map( order => {

                            let style = {};

                            let pr_order = {
                                cda_stato_doc: '-'
                            };

                            if( order.pr_order ){
                                pr_order = order.pr_order;

                                if( pr_order.cda_stato_doc == 'EVA' ){
                                    style['color'] = 'red';
                                }
                            }

                            return <tr key={order.increment_id} style={style}>
                                <td>{order.increment_id}</td>
                                <td>{order.created_at}</td>
                                <td>{order.customer_firstname} {order.customer_lastname}</td>
                                <th>{order.payment_method}</th>
                                <td>{order.status}</td>
                                <td>{pr_order.cda_stato_doc}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div> }
        </BoxContainer>
    }

}

class AdminInstructionsTitleEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mode: 'view', // view | edit | save
            title: this.props.title
        };

        this.switchToEdit = this.switchToEdit.bind( this );
        this.onSave = this.onSave.bind( this );
    }


    switchToEdit(se){
        se.preventDefault();
        this.setState({
            mode: 'edit'
        });
    }

    onSave( se ){
        se.preventDefault();

        this.setState({
            mode: 'save'
        });

        pweb().fetch( 'admin' , 'instructions' , 'update' , this.props._id , this.state.title , msg => {
            if( msg ){
                this.setState({ mode: 'view' });
            }
        });
    }

    render() {

        if( this.state.mode == 'view' ){
            return <div>
                {this.state.title ? decodeURIComponent( this.state.title ) : this.props.URL_MONTAGGIO}
                <button className={"btn btn-xs btn-secondary"} onClick={this.switchToEdit}>
                    <i className={"fa fa-edit"}></i></button>
            </div>
        }

        if( this.state.mode == 'edit' ){
            return <div>
                <input type="text" value={decodeURIComponent( this.state.title )} onChange={se=> {
                    this.setState({title: se.target.value})
                }} />

                <button className={"btn btn-xs btn-secondary"} onClick={this.onSave}>
                    <i className={"fa fa-edit"}></i></button>
            </div>
        }

        if( this.state.mode == 'save' ){
            return <span>Salvataggio...</span>
        }

        return <div></div>
    }
}

class AdminUsers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rows: [],
            cda_ana: '',
        };

        this.updateData();
        this.onCdaAnaChange = this.onCdaAnaChange.bind( this );
        this.onInputFileChange = this.onInputFileChange.bind( this );
        this.fileInput = React.createRef();
    }

    updateData(){
        this.setState({ loading: true });

        pweb().fetch( 'admin' , 'users' , { cda_ana: this.state.cda_ana }, msg => {
            if( msg ){
                this.setState({ rows: msg, loading: false});
            }
        });
    }

    onInputFileChange( se ){
        pweb().fetch( 'admin', 'users' , 'upload' , this.cda_ana_upload , { logo: this.fileInput.current.files[0] } , ( data) => {
            this.updateData();
        });
    }

    onLoadLogo( user ){
        this.fileInput.current.click();
        this.cda_ana_upload = user.CDA_ANA;
    }

    onCdaAnaChange( se ){
        this.setState({ cda_ana: se.target.value });

        if( this.cdaAnaTimeout )
            clearTimeout( this.cdaAnaTimeout );

        this.cdaAnaTimeout = setTimeout( () => {
            this.updateData();
        } , 1000 );
    }

    render() {
        return <Row>

            <div className={"col-md-6"}>
                <FormInputText label={t('Filtro Codice anagrafica')} value={this.state.cda_ana}
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={this.onCdaAnaChange} />
            </div>

            { this.state.loading ? <Loader/> : null }

            { !this.state.loading && <div className={"col-md-12"}>
                <BoxContainer>

                    <input style={{display: 'none'}} type="file" ref={this.fileInput} onChange={this.onInputFileChange} />

                    <table className={"table table-hover table-striped"}>
                        <thead>
                        <tr>
                            <th>Ana</th>
                            <th>PIva</th>
                            <th>Ragione Sociale</th>
                            <th>Indirizzo</th>
                            <th>Logo</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.rows.map( row => {
                            return <tr>
                                <td>{row.CDA_ANA}</td>
                                <td>{row.CDA_PIVA}</td>
                                <td>{row.CDA_RAGSOC}</td>
                                <td>{row.CDA_INDIR}</td>
                                <td>
                                    {row.logo_token ? <img src={`${window.location.protocol}//${window.location.hostname}/api1/user/logo/${row.logo_token}`} width={100}/>
                                      : '-'}
                                </td>
                                <td>
                                    <button className={"btn btn-primary btn-xs"} onClick={se=>{
                                        se.preventDefault();
                                        this.onLoadLogo( row );
                                    }}>
                                        <i className={"fa fa-upload"}></i>Carica Logo</button>
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                </BoxContainer>
            </div> }
        </Row>;
    }

}

class AdminDownloadsEdit extends Component {
    constructor( props ) {
        super( props );

        this.state = {
            loading: true
        }

        this.fileInput = React.createRef();

        this.onSave = this.onSave.bind( this );
        this.onDelete = this.onDelete.bind( this );
        this.onCategoryEdit = this.onCategoryEdit.bind( this );
        this.onSubCategoryEdit = this.onSubCategoryEdit.bind( this );
        this.onInputFileChange = this.onInputFileChange.bind( this );
    }

    onDelete( se ){
        se.preventDefault();

        if( !this.state._id )
            return;

        pweb().fetch( 'admin' , 'downloads' , 'delete' , this.state._id , msg => {
            if( msg ){
                window.location = '/system/downloads_admin';
            }
        });
    }

    onSave(){
        this.setState({ loading: true });

        pweb().fetch( 'admin' , 'downloads' , 'save' , this.state, msg => {
            if( msg ){
                window.location.reload();
            }
        });
    }

    componentDidMount() {
        let id = window.location.pathname.match( /downloads_edit\/(.+)/ );

        if( id ){
            pweb().fetch( 'admin' , 'downloads' , 'get' , id[1] , msg => {
                if( msg ){
                    this.setState({ ...msg, loading: false});
                }
            });
        } else {
            this.setState({ loading: false });
        }
    }

    onInputFileChange( id ){
        //se.preventDefault();

        if( !this.fileInput.current )
            return;

        if( !this.fileInput.current.files.length )
            return;

        this.setState({ loading: true });

        let file = this.fileInput.current.files[0];

        pweb().fetch( 'admin', 'downloads', 'upload' , this.state._id ? this.state._id: '0', { file: file } , data => {
            pweb().fetch( 'admin' , 'downloads' , 'save' , { ...this.state , _id: data._id }, msg => {
                window.location = '/system/downloads_edit/' + data._id;
            });
        });

    }

    onCategoryEdit( se ){
        if( !this.state.des_categoria ){
            this.setState({
                des_categoria: se.target.value
            });
            return;
        }

        let splitted = this.state.des_categoria.split('-');

        this.setState({
            des_categoria: se.target.value + (splitted.length > 1 ? '-' + splitted[1] : '')
        });
    }

    onSubCategoryEdit( se ){
        let splitted = this.state.des_categoria.split('-');

        if( se.target.value.length < 1 ){
            this.setState({des_categoria: splitted[0]});
            return;
        }

        this.setState({
            des_categoria: splitted[0] + '-' + se.target.value
        });
    }

    render(){

        if( this.state.loading )
            return <Loader />;

        let cat = this.state.des_categoria;
        let sub_cat = '';

        try {
            let splitted = cat.split('-');
            cat = splitted[0];
            sub_cat = splitted[1];
        } catch (e) {

        }

        return <Row>

            <div className={"col-md-12"}>
                <BoxContainer title={"Modifica oggetto"}
                              tools={<button className={"btn btn-sm"} onClick={this.onDelete}>Elimina file</button>}>
                    <form className="" onSubmit={this.onSave}>

                        <input style={{display: 'none'}} type="file" ref={this.fileInput} onChange={this.onInputFileChange} />

                        <div className="row">
                            <div className="col-lg-6 col-sm-12 mb-5">
                                <FormInputText label={t('Nome')} value={this.state.cda_nome}
                                               direction={FormInputContainer.HORIZONTAL}
                                               onChange={se=>{this.setState({cda_nome: se.target.value})}} />
                            </div>
                        </div>

                        <Row>
                            <div className="col-lg-6 col-sm-12">
                                <FormInputText label={t('Categoria')} value={cat}
                                               direction={FormInputContainer.HORIZONTAL}
                                               onChange={this.onCategoryEdit} />
                            </div>
                        </Row>

                        <Row>
                            <div className="col-lg-6 col-sm-12">
                                <FormInputText label={t('Sotto categoria')} value={sub_cat}
                                               direction={FormInputContainer.HORIZONTAL}
                                               onChange={this.onSubCategoryEdit} />
                            </div>
                        </Row>

                        <Row>
                            <div className={"col-6"} style={{marginLeft: '15px'}}>
                                <button className={"btn btn-primary"} onClick={ se => {
                                    se.preventDefault();
                                    this.fileInput.current.click();
                                }}>Carica file</button>
                            </div>
                        </Row>

                        <FormDashline />

                        <div className="row">
                            <FormButtons onSave={this.onSave} onCancel={se=>{Route.pushLocation('/system/downloads_admin')}} />
                        </div>

                    </form>

                </BoxContainer>
            </div>
        </Row>
    }
}

class AdminDownloads extends Component {

    constructor(props){
        super(props);

        this.state = {
            loading: true
        };

        this.updateData();
        this.onLoadPdf = this.onLoadPdf.bind( this );
        this.onInputFileChange = this.onInputFileChange.bind( this );

        this.forceFrontendUpdate = this.forceFrontendUpdate.bind( this );

        this.fileInput = React.createRef();

        this.toUploadId = false;
    }

    updateData(){
        this.setState({ loading: true });

        pweb().fetch( 'admin' , 'downloads' , msg => {
            if( msg ){
                this.setState({ rows: msg, loading: false});
            }
        });
    }

    onInputFileChange( id ){
        //se.preventDefault();

        if( !this.fileInput.current )
            return;

        if( !this.fileInput.current.files.length )
            return;

        this.setState({ loading: true });

        let file = this.fileInput.current.files[0];
        pweb().fetch( 'admin', 'instructions', 'upload' , this.toUploadId , { image: file } , data => {
            this.updateData();
        });

    }

    onLoadPdf( se ){
        if( se.target === this.fileInput.current )
            return;

        se.preventDefault();

        this.fileInput.current.click();
    }

    forceFrontendUpdate( se ){
        se.preventDefault();

        pweb().fetch( 'admin', 'instructions', 'forceUpdate' , data => {
            window.location.reload();
        });
    }

    render() {
        if( this.state.loading )
            return <Loader />;

        return <Row>

            <div className={"col-md-12"} style={{marginBottom: '15px'}}>
                <button className={"btn btn-primary"} onClick={ se => {
                    window.location = '/system/downloads_edit';
                }}>Nuovo file</button>
            </div>

            <div className={"col-md-12"}>
                <BoxContainer>

                    <input style={{display: 'none'}} type="file" ref={this.fileInput} onChange={this.onInputFileChange} />

                    <table className={"table table-hover table-striped"}>
                        <thead>
                        <tr>
                            <th>Nome visualizzato</th>
                            <th>Categoria</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.rows.map( row => {
                            return <tr>
                                <td>{row.cda_nome}</td>
                                <td>{row.des_categoria}</td>
                                <td>
                                    <button className={"btn btn-primary btn-xs"} onClick={se=>{
                                        window.location = '/system/downloads_edit/' + row._id;
                                    }}>
                                        <i className={"fa fa-edit"}></i> Modifica</button>
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                </BoxContainer>
            </div>
        </Row>;
    }
}

class AdminInstructions extends Component {

    constructor(props){
        super(props);

        this.state = {
            loading: true
        };

        this.updateData();
        this.onLoadPdf = this.onLoadPdf.bind( this );
        this.onInputFileChange = this.onInputFileChange.bind( this );

        this.forceFrontendUpdate = this.forceFrontendUpdate.bind( this );

        this.fileInput = React.createRef();

        this.toUploadId = false;
    }

    updateData(){
        this.setState({ loading: true });

        pweb().fetch( 'admin' , 'instructions' , msg => {
            if( msg ){
                this.setState({ rows: msg, loading: false});
            }
        });
    }

    onInputFileChange( id ){
        //se.preventDefault();

        if( !this.fileInput.current )
            return;

        if( !this.fileInput.current.files.length )
            return;

        this.setState({ loading: true });

        let file = this.fileInput.current.files[0];
        pweb().fetch( 'admin', 'instructions', 'upload' , this.toUploadId , { image: file } , data => {
            this.updateData();
        });

    }

    onLoadPdf( se ){
        if( se.target === this.fileInput.current )
            return;

        se.preventDefault();

        this.fileInput.current.click();
    }

    forceFrontendUpdate( se ){
        se.preventDefault();

        pweb().fetch( 'admin', 'instructions', 'forceUpdate' , data => {
            window.location.reload();
        });
    }

    render() {
        if( this.state.loading )
            return <Loader />;

        return <Row>

            <div className={"col-md-12"} style={{marginBottom: '15px'}}>
                <button className={"btn btn-primary"} onClick={this.forceFrontendUpdate}>Forza aggiornamento frontend</button>
            </div>

            <div className={"col-md-12"}>
                <BoxContainer>

                    <input style={{display: 'none'}} type="file" ref={this.fileInput} onChange={this.onInputFileChange} />

                    <table className={"table table-hover table-striped"}>
                        <thead>
                        <tr>
                            <th>URL</th>
                            <th>Url video</th>
                            <th>Titolo prodotto</th>
                            <th>Pdf</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.rows.map( row => {
                            return <tr>
                                <td>{row.URL_MONTAGGIO}</td>
                                <td>{row.URL_QRCODE}</td>
                                <td><AdminInstructionsTitleEditor {...row} /></td>
                                <td>{row.pdf ? row.pdf.name : '-'}</td>
                                <td>
                                    <button className={"btn btn-primary btn-xs"} onClick={se=>{
                                        this.onLoadPdf( se );
                                        this.toUploadId = row._id;
                                    }}>
                                        <i className={"fa fa-upload"}></i> Carica PDF</button>
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                </BoxContainer>
            </div>
        </Row>;
    }
}

class AdminBlockedOrders extends Component {

    constructor(props){
        super(props);

        this.state = {
            loading: true,
            detail: false,
        };

        pweb().fetch( 'admin' , 'borders' , msg => {
            if( msg ){
                this.setState({ rows: msg, loading: false});
            }
        });

    }

    render(){
        if( this.state.loading )
            return <Loader />;

        if( this.state.detail ){
            const order = this.state.detail;

            return <Row>
                <div className="col-md-12">

                    <BoxContainer title={"Dettaglio ordine"}>

                        <a onClick={ se => {
                            se.preventDefault();
                            this.setState({ detail: false });
                        }}>Indietro</a>

                        <h4>{this.state.detail.prg_doc} / {order.num_eser_cont}</h4>

                        <p>Cliente: {order.cda_ana}</p>
                        <p>Data: {order.dat_creazione}</p>
                        <p>Stato: {order.sync_post_response}</p>

                        {order.rows.filter( row => !row.flg_canc_logica ).map( row => {
                            return <div>
                                <h4>Riga {row.prg_riga}</h4>
                                <p>Articolo: {row.cda_art}</p>
                                <p>Stato: {row.sync_post_status}</p>
                                <p>Ultimo tentativo: {row.sync_post_last_attempt}</p>
                                <p>Stringa inviata: {row.sync_post_data}</p>
                                <p>Ricevuto: {row.sync_post_response}</p>
                            </div>;
                        })}

                    </BoxContainer>
                </div>
            </Row>
        }

        return <Row>
            <div className="col-md-4">

            </div>

            <div className="col-md-12">
                <BoxContainer title="Ordini bloccati">
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>Preventivo</th>
                            <th>Cliente</th>
                            <th>Data</th>
                            <th></th>
                            <th>Righe ok / totali</th>
                        </tr>
                        </thead>
                        <tbody>
                            { ( this.state.rows && this.state.rows.map && this.state.rows.map( row => {
                                const rows = row.rows.filter( row => !row.flg_canc_logica);

                                return <tr>
                                    <td>{row.prg_doc} / {row.num_eser_cont}</td>
                                    <td>{row.cda_ana}</td>
                                    <td>{row.dat_creazione}</td>
                                    <td>
                                        {row.retry_sync_manually ? <span className="label label-warning">
                                            Riprovo invio in corso</span>
                                          : row.sync_post_response}
                                    </td>
                                    <td>{rows.filter( row => row.sync_post_status == 'ok').length} / {rows.length}</td>
                                    <td>
                                        <a className="btn btn-sm btn-primary" onClick={ se => {
                                            se.preventDefault();
                                            this.setState({ detail: row });
                                        }}>Dettaglio</a>

                                        <a className="btn btn-sm btn-default" onClick={ se => {
                                            se.preventDefault();
                                            pweb().fetch( 'admin' , 'borders' , 'dismiss' , row._id.toString() , msg => {

                                            });
                                            this.setState({ rows: this.state.rows.filter( r => r._id != row._id ) } );
                                        }}>Nascondi</a>

                                        <a className="btn btn-sm btn-default" onClick={ se => {
                                            se.preventDefault();
                                            pweb().fetch( 'admin' , 'borders' , 'retry' , row._id.toString() , msg => {

                                            });
                                            this.setState({
                                                rows: this.state.rows.map(r => {
                                                    if( r._id != row._id )
                                                        return r;

                                                    return { ...r, retry_sync_manually: true }
                                                })
                                            });
                                        }}>Riprova invio</a>

                                    </td>
                                </tr>
                            }))}
                        </tbody>
                    </table>
                </BoxContainer>
            </div>
        </Row>;
    }


}

class AdminRealtimeStats extends Component {
    constructor(props){
        super(props);

        this.state = {
            loading: true
        };

        this.updateData();
    }

    updateData(){
        pweb().fetch( 'admin' , 'realtime_stats' , msg => {
            if( msg.data ){
                let data = msg.data;
                data.active_users = data.active_users.map( user => {
                    user.extra_info = JSON.parse( user.extra_info );
                    return user;
                });

                this.setState({ ...data, loading: false});
            }
        });
    }

    componentWillUnmount(){
        clearInterval( this.timer );
    }

    componentDidMount(){
        this.timer = setInterval( ()=>{ this.updateData() } , 10 * 1000 );
    }

    render(){

        if( this.state.loading )
            return <Loader />;

        return <Row>
            <div className="col-md-4">
                
            </div>
            <div className="col-md-8">
                <BoxContainer title="Utenti collegati">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Utente</th>
                                <th>Data accesso</th>
                                <th>Ip</th>
                                <th>Url</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.active_users.map( access => {
                                let style = {display: 'table-row'};

                                let action = access.action;
                                if( action == 'login' ) action = 'Nuovo';
                                if( action == 'check_key' ) action = 'Accesso';
                                if( access.ip_address )
                                    if( access.ip_address.match( /(::1|10\.130\.1\.[0-9]+)/i ) )
                                        style.color = 'lightgrey';

                                return <tr key={access.user_id} style={style}>
                                    <td>{access.user_id}</td>
                                    <td>{moment(access.dat_created).format('DD/MM/YY HH:mm:ss')}</td>
                                    <td>{access.ip_address}</td>
                                    <td>{access.extra_info ? access.extra_info.url: ''}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                    
                </BoxContainer>
            </div>
        </Row>
    }
}

class AdminRoutes extends Component {
    render(){
        return (

            [ <Route url='/system/users_login_report' >
                <Row>
                    <div className="col-md-5">
                        <AdminUsersLoginReport />
                    </div>
                    <div className="col-md-7">
                        <AdminUsersActionReport />
                    </div>

                    <div className="col-md-7">
                        <AdminUsersBanned />
                    </div>
                </Row>
            </Route> , 
            <Route url='/system/nf_order_compare'>
                <NfOrderCompare />
            </Route> ,
            <Route url="/system/banners_list">
                <AdminUsersActionBannersList />
            </Route>,
            <Route url="/system/banners_edit">
                <AdminUsersActionBannersEdit />
            </Route>,
            <Route url="/system/instructions">
                <AdminInstructions />
            </Route>,
            <Route url="/system/downloads_admin">
                <AdminDownloads />
            </Route>,
            <Route url="/system/orders">
                <AdminBlockedOrders />
            </Route>,
            <Route url="/system/downloads_edit">
                <AdminDownloadsEdit />
            </Route>,/*
            <Route url="/system/users">
                <AdminUsers />
            </Route>,*/
            <Route url='/system/realtime_stats'>
                <AdminRealtimeStats />
            </Route>]
        );
    }
}

function AdminNavigationMenu( props ){
    return <NavigationMenuItem fa="fa fa-cogs" label={t('System')} url="/system">
        <NavigationMenuSubItem label={t('Report accessi')} url="/system/users_login_report" />
        {/*
        <NavigationMenuSubItem label={t('Tempo reale')} url="/system/realtime_stats" />
        <NavigationMenuSubItem label={t('Nf Report')} url="/system/nf_order_compare" />
        <NavigationMenuSubItem label={t('Banners')} url="/system/banners_list" />*/}
        <NavigationMenuSubItem label={t('Istruzioni')} url="/system/instructions" />
        <NavigationMenuSubItem label={t('Downloads')} url="/system/downloads_admin" />
        <NavigationMenuSubItem label={t('Loghi utenti')} url="/system/users" />
        <NavigationMenuSubItem label={t('Ordini bloccati')} url="/system/orders" />
    </NavigationMenuItem>;
}

export { AdminRoutes, AdminUsersLoginReport, AdminNavigationMenu }