
import React, { Component } from 'react';
import { pweb , pwebs, Link, Route, BoxContainer, 
    Loader, Utils, ScrollBasedList } from './Common.js';
import moment from 'moment';
import t from './i18n';
import { OrdersTable, OrdersUtil } from './Order.js'

class DocumentsList extends Component {

    constructor(props){
        super(props);

        this.state = {
            loading: true,
            documents: []
        };
    }

    load( nextProps ){
        this.setState({loading: true, documents: []});

        const options = {
            page: 0,
            year: moment().year(),
            limit: 20,
            states: this.props.states,
            ...nextProps,
            order: 'PRIMED.MGXX_DOCTEST.NUM_ESER_CONT DESC, PRIMED.MGXX_DOCTEST.PRG_DOC DESC',
        };

        const fetchFunction = this.props.cache ? pweb().passiveCacheFetch.bind(pweb()) : pweb().fetch.bind( pweb() );

        fetchFunction( 'order' , 'fetchDocuments' , options ,  data => {
            if( !data.status )
                return;

            this.setState({
                documents: data.data.rows,
                loading: false,
            });

            if( this.props.onDocumentsLoaded )
                this.props.onDocumentsLoaded( data.data.rows );
        } );
        
    }

    componentWillReceiveProps( nextProps ){
        
        if( JSON.stringify( this.props ) === JSON.stringify( nextProps ) )
            return;

        this.load( nextProps );
    }

    static printDocument( order ){

    }

    static columnActions(order, isLabel, onClick){
        return isLabel ? 
            <th className="hidden-md hidden-sm hidden-xs"></th> : 
            <td className="text-left"> 
                <a target="_blank" href={`/print/${OrdersUtil.formatNum(order,true)}`} title="Stampa" className="btn btn-default btn-xs" >{t('Print')}</a>
                { order.dat_cons_rich && order.cda_tipo_doc_num == 'BV' && 
                    <a target="_blank" href={`/print/ORDERSUMM-${OrdersUtil.formatNum(order,true)}`} title="Stampa ddt" className="btn btn-default btn-xs" >{t('Ddt Summary')}</a>
                }
                { OrdersUtil.hasTracking( order ) && 
                    <a target="_blank" href={`/order/tracking/${OrdersUtil.formatNum(order,true)}`} 
                        className="btn btn-default btn-xs" title="Traccia" >{t('Track')}</a>
                }
            </td>;
    }

    static columnGrandTotalRow(order, isLabel, onClick){
        return isLabel ? 
            <th className="hidden-xs hidden-md">{t('Total')}</th> : 
            <td onClick={onClick} className="hidden-md" >{Utils.formatCurrency( order.val_tot_fattura||order.sum_val_movim_lordo )}</td>;
    }

    componentDidMount(){
        this.load( this.props );

        pwebs().dispatch( pweb().setHeaderBreadcrumbs([
            {label: 'Documenti fiscali' , url:'#'},
        ]));
    }

    render(){

        const columns = this.props.columns ? this.props.columns : [ 
            OrdersTable.columnDocumentNumber, OrdersTable.columnCreationDate,
            DocumentsList.columnGrandTotalRow, DocumentsList.columnActions ];

        return <div>
            {this.state.documents.length ? (

                <div className="row DocumentList">
                    <div className="col-sm-12" style={{padding: '20px'}}>
                        <OrdersTable orders={this.state.documents} {...this.props} columns={columns}
                            onRowClick={order => {}} />

                        <Link href={"/documents/full/"+this.props.doc_types[0]} className="btn btn-default">Vedi tutti</Link>
                    </div>
                </div>

            ) : (

                <div>
                    {this.state.loading? ( <Loader /> ) : (
                        <p style={{textAlign: 'center', fontStyle: 'italic', padding: '20px'}} >{t('No elements founds.')}</p>
                    )}
                </div>
                
            )}

        </div>;
    }
}


class DocumentsListMonth extends Component {

    constructor(props){
        super(props);

        this.state = {
            orders: false,
            ajaxState: DocumentsListMonth.AJAX_STATUS_WAITING
        };
    }

    static AJAX_STATUS_WAITING = 1;
    static AJAX_STATUS_LOADING = 2;
    static AJAX_STATUS_LOADED = 3;

    componentDidMount(){
        this.setState({ajaxState: DocumentsListMonth.AJAX_STATUS_LOADING});

        const startDate = moment( this.props.startDate ).startOf('month').format('DD/MM/YYYY');
        const endDate = moment( this.props.startDate ).endOf('month').format('DD/MM/YYYY');

        const options = {
            ...this.props,
            page: 0,
            startDate: startDate,
            endDate: endDate,
            year: this.state.year,
            fetchStats: this.props.stats
        };

        pweb().fetch( 'order' , 'fetchDocuments' , options ,  data => {
            if( !data.status )
                return;

            this.setState({
                orders: data.data.rows,
                stats: data.data.stats,
                ajaxState: DocumentsListMonth.AJAX_STATUS_LOADED,
            });

            if( this.props.onLoadComplete )
                this.props.onLoadComplete();
        } );

    }

    static columnTrack(order, isLabel, onClick){
        return isLabel ? 
            <th className="hidden-md hidden-sm hidden-xs" width="5%"></th> : 
            <td className="text-left">
                {OrdersUtil.hasTracking( order ) && <Link className="btn btn-xs btn-default" target="_blank"
                    data-toggle="tooltip" data-placement="left" title="Traccia spedizione"
                    href={`/order/tracking/${OrdersUtil.formatNum(order,true)}`}>
                    <i className="fa fa-truck"></i>
                </Link>}
            </td>;
    }

    render(){

        if( this.state.ajaxState === DocumentsListMonth.AJAX_STATUS_LOADING ||
            this.state.ajaxState === DocumentsListMonth.AJAX_STATUS_WAITING )
            return <Loader />;

        const columns = this.props.columns ? this.props.columns : [ 
            OrdersTable.columnDocumentNumber, OrdersTable.columnCreationDate,
            DocumentsList.columnGrandTotalRow, DocumentsList.columnActions ];

        return <div className="">

            {this.state.stats ? (
                <p className="text-right">
                    {this.state.stats[0].row_count} righe trovate
                </p>
            ) : ''}
        
            <div className="row">
                <div className="col-sm-12">
                    <h2>{moment(this.props.startDate).format('MMMM YYYY')}</h2>
                </div>
            </div>

            {this.state.orders.length ? (

                
            <div className="row">
                <div className="col-sm-12" style={{padding: '20px'}}>
                    <OrdersTable orders={this.state.orders} columns={columns} />
                </div>
            </div>

            ) : (
                <p className="NoItemFound"  >{t('No elements founds.')}</p>
            )}
            
            
        </div>;

    }
}

class MonthsScrollBasedDocumentsList extends Component {

    constructor(props){
        super(props);

        this.requestNextItemIdentifier = this.requestNextItemIdentifier.bind(this);
        this.requestItem = this.requestItem.bind(this);
    }

    componentWillReceiveProps(){
    }

    requestItem( identifier , itemReady = ()=>{} ){
        return <DocumentsListMonth {...this.props} key={identifier} 
            /*stats={this.props.startDate == identifier}*/
            stats={false}
            startDate={identifier}
            onLoadComplete={itemReady} />
    }

    requestNextItemIdentifier( previous ){
        if( !previous )
            return moment( this.props.startDate ).format();

        return moment( previous ).startOf('month').subtract(2,'days').format();
    }

    render(){
        return <ScrollBasedList startDate={this.props.startDate} requestNextItemIdentifier={this.requestNextItemIdentifier}
            requestItem={this.requestItem} />;
    }
}

class DocumentsListByMonth extends Component {

    constructor(props){
        super(props);

        this.state = {
            year: (new Date()).getFullYear(),
            month: moment().month(),
            doc_types: [ Utils.getUrlParam( -1 , 'BV' ) ]
        };
    }

    render(){

        const years = []; for( let i = moment().year(); i >= 2000; i-- ) years.push( i );

        const months = []; for( let i = 0; i <= 11; i++ ) months.push( i );

        const types = {
            BV: 'Delivery notes',
            BC: 'Credit notes',
            ND: 'Debit notes',
            FT: 'Invoices'
        };

        const startDate = moment().set({
            year: this.state.year,
            month: this.state.month,
            second: 59,
            minute: 59,
            hour: 23,
        }).add('1 day').format();

        return <BoxContainer title={t('Docuemnts')} id="OrderList">

            <div className="">

                <form onSubmit={this.onSearchSubmit} className="form-horizontal">

                    <div className="row">

                        <div className="form-group col-md-4 col-sm-4 OrdersListByMonthFilter">
                            <label className="control-label col-md-5 col-xs-5" style={{width: 'auto'}}>{t('Type:')}</label>
                            <div className="col-md-7 col-xs-7">
                                <select style={{width:'auto'}} className="form-control" onChange={se=> this.setState({doc_types: [ se.target.value ]})} >
                                    {Object.keys(types).map( type => {
                                        return this.state.doc_types[0] === type ? <option selected value={type}>{t(types[type])}</option> : 
                                            <option value={type}>{t(types[type])}</option>;
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="form-group col-md-4 col-sm-4 OrdersListByMonthFilter">
                            <label className="control-label col-md-5 col-xs-5" style={{width: 'auto'}}>{t('Year:')}</label>
                            <div className="col-md-7 col-xs-7">
                                <select style={{width:'auto'}} className="form-control" onChange={se=> this.setState({year: se.target.value})} >
                                    {years.map( year => {
                                        return this.state.year === year ? <option selected value={year}>{year}</option> : 
                                            <option value={year}>{year}</option>;
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="form-group col-md-4 col-sm-4 OrdersListByMonthFilter">
                            <label className="control-label col-md-5 col-xs-5" style={{width: 'auto'}}>{t('Month:')}</label>
                            <div className="col-md-7 col-xs-7">
                                <select style={{width:'auto'}} className="form-control" onChange={se=> this.setState({month: se.target.value})}>
                                    {months.map( month => {
                                        const name = moment( month+1 , 'M' ).format('MMMM');
                                        return this.state.month === month ? <option selected value={month}>{name}</option> : 
                                            <option value={month}>{name}</option>;
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>

                </form>

                <MonthsScrollBasedDocumentsList doc_types={this.state.doc_types} {...this.props} startDate={startDate} />

            </div>
        </BoxContainer>;

    }
}

function DocumentsRoutes(props){
    return <div>
        <Route url='/documents/full'>
            <DocumentsListByMonth />
        </Route>

        <Route url='/documents/active'>
            <div className="row">

                <div className="col-md-12">
                    <BoxContainer title={t('Delivery notes')}>
                        <DocumentsList FLG_BOLLA_FATT={0} doc_types={['BV']}  states={{nin: [ 'ANN' , 'TRA', 'EVA' ]}} />
                    </BoxContainer>
                </div>

            </div>
            
        </Route>

        <Route url='/documents/report'>
            <div className="row">

                <div className="col-md-6">
                    <BoxContainer title={t('Delivery notes')}>
                        <DocumentsList doc_types={['BV']} columns={[ 
                            OrdersTable.columnDocumentNumber, OrdersTable.columnCreationDate,
                            DocumentsList.columnGrandTotalRow, (order, isLabel, onClick) => {
                                return isLabel ? 
                                    <th className="hidden-md hidden-sm hidden-xs"></th> : 
                                    <td className="text-left"> 
                                        <a target="_blank" href={`/print/${OrdersUtil.formatNum(order,true)}`} title="Stampa DDT" className="btn btn-default btn-xs" >{t('Print Ddt')}</a>
                                        { order.dat_cons_rich && order.cda_tipo_doc_num == 'BV' && 
                                            <a target="_blank" href={`/print/ORDERSUMM-${OrdersUtil.formatNum(order,true)}`} title="Riepilogo" className="btn btn-default btn-xs" >{t('Ddt Summary')}</a>
                                        }
                                        { OrdersUtil.hasTracking( order ) && 
                                            <a target="_blank" href={`/order/tracking/${OrdersUtil.formatNum(order,true)}`} title="Traccia"
                                                className="btn btn-default btn-xs" >{t('Track')}</a>
                                        }
                                    </td>;
                            } ]} />
                    </BoxContainer>

                    <BoxContainer title={t('Credit notes')}>
                        <DocumentsList doc_types={['NC']} printLabel={t('Print invoice')} />
                    </BoxContainer>
                </div>

                <div className="col-md-6">
                    <BoxContainer title={t('Invoices')}>
                        <DocumentsList doc_types={['FT']} printLabel={t('Print invoice')} />
                    </BoxContainer>

                    <BoxContainer title={t('Debit notes')}>
                        <DocumentsList doc_types={['ND']} printLabel={t('Print invoice')} />
                    </BoxContainer>
                </div>

            </div>
            
        </Route>
    </div>;
}

export { DocumentsList , DocumentsListByMonth, DocumentsRoutes };
