
import superagent from 'superagent';
import async from 'async';
import Globals from './Globals.js'

const __t_Object = {

    applyArguments: function( text ){
        let r = text;
        const args = Array.from( arguments ).splice( 1 );

        args.map( value => {
            r = r.replace( /\%[^\%]/ , value );
        });

        return r;
    },

    translate: function( text ){

        let argsArray = Array.from( arguments );

        if( !__t_Object._currentLanguage )
            return __t_Object.applyArguments.apply( undefined , argsArray );

        if( !__t_Object._languages[__t_Object._currentLanguage] )
            return __t_Object.applyArguments.apply( undefined , argsArray );

        if( !__t_Object._languages[__t_Object._currentLanguage][text] )
            return __t_Object.applyArguments.apply( undefined , argsArray );

        argsArray = [ __t_Object._languages[__t_Object._currentLanguage][text] ].concat( argsArray.splice( 1 ) );

        return __t_Object.applyArguments.apply( undefined , argsArray );
    },

    setLanguage: function( lang ){
        __t_Object._currentLanguage = lang;
    },

    _currentLanguage: false,

    _languages: {},

    preload: function( languages , cb ){
        async.map( languages , (language, l_cb) => {

            if( t()._languages[language] )
                return l_cb();

            const time = Math.floor( (new Date()).getTime() / 3600000 );

            superagent.get( `/locale/${language}.csv?t=${time}` ).end( (err, data) => {
                __t_Object._languages[language] = {};
                data.text.split( /[\n\r]+/ ).map( line => {

                    if( !line )
                        return true;
                        
                    if( !line.length )
                        return true;

                    const parts = /(.+[^\\]),(.*)/.exec( line );
                    if( !parts ){
                        console.error( `Invalid i18n line "${line}"`);
                        return "";
                    }

                    __t_Object._languages[language][parts[1]] = parts[2];

                    return true;
                });
                l_cb();
            });
        } , cb );
    },

};

function t( arg0 ){
    
    if( typeof arg0 === 'undefined' )
        return __t_Object;

    return __t_Object.translate.apply( undefined , Array.from( arguments ) );
}

export default t;