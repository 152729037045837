import React from 'react';
import ReactDOM from 'react-dom';
import PrivateArea from './PrivateArea';
import t from './i18n';

t().preload(['it','fr'], (err,data) => {
    ReactDOM.render(<PrivateArea />, document.getElementById('wrapper'));
});


