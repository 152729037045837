import React, { Component } from 'react';
import './PrivateArea.css';
import './template/style.css';
import t from './i18n';
import { PriWebApp } from './Globals';
import { pweb , pwebs, PrimedLogo, FormInputPassword, FormInputMap, FormInputImage,
    FormInputText, FormButtons, FormInputContainer , FormDashline, FormInputSelect,
    FormInputSelectOption, Route, BoxContainer, Loader, Link,
     } from './Common.js';


class GeneratePasswordFlow extends Component {
    constructor(props){
        super(props);

        const match = window.location.pathname.match( /^\/recoverPassword([0-9])\/([a-z0-9\-]+)\/?$/i );

        this.state = {
            state: 1,
            token: match[2],
            type: match[1],
            password: '',
            password_retype: '',
            loading: false,
            errorMessage: '',
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(se){
        se.preventDefault();

        if( this.state.loading )
            return;

        const msg = t('Invalid credential or wrong password');

        if( !this.state.password )
            return this.setState({errorMessage: msg });

        if( !this.state.password.length )
            return this.setState({errorMessage: msg });

        if( this.state.password_retype !== this.state.password )
            return this.setState({errorMessage: 'Le password inserite non coincidono' });


        this.setState({loading: true,errorMessage:''});

        PriWebApp.fetch( 'user' , 'changePassword' , this.state , ( data ) => {

            this.setState({loading: false});

            if( data.status ){

                PriWebApp.fetch( 'user' , 'login' , {email: data.data, password: this.state.password} , ( data ) => {

                    if( data.status ){
                        pwebs().dispatch( pweb().login( data.data.token ) );
                        pwebs().dispatch( pweb().setUserData( data.data.user ) );
                        window.location = '/';
                    } else {
                        this.setState({
                            errorMessage: '',
                            state: 2
                        });
                    }
                });


            } else {
                this.setState({errorMessage: msg});
            }

        });
    }

    componentDidMount(){
        document.body.className = "gray-bg";
    }

    componentWillUnmount(){
        document.body.className = "";
    }

    render(){
      
        let content = <Loader />;

        if( this.state.state == 3 )
            return <Login />;

        if( this.state.state == 2 ){
            content = <div><p>{this.state.type == 2 ? 'Password cambiata correttamente' : 'Registrazione effettuata correttamente'}</p>
                <a target="_blank" href="/">Clicca qui per effettuare l'accesso</a>
            </div>;
        }

        if( this.state.state == 1 ){
            content = <form className="m-t" action="">

                <div className="form-group">
                    <label className="form-label">Inserisci una nuova password:</label>
                    <input type="password" className="form-control"
                    placeholder="Nuova password" required="" 
                    onChange={se=> this.setState({password: se.target.value, errorMessage: ''})} />
                </div>

                <div className="form-group">
                    <label className="form-label">Reinserisci la password:</label>
                    <input type="password" className="form-control"
                    placeholder="Reinserisci password" required="" 
                    onChange={se=> this.setState({password_retype: se.target.value, errorMessage: ''})} />
                </div>

                <div className="form-group">
                    <p style={{color: 'red'}}>{this.state.errorMessage}</p>
                </div>

                <button type="submit" className="btn btn-primary block full-width m-b" onClick={this.onSubmit}>
                    {!this.state.loading?<span>Salva password</span>:<i className="fa fa-spinner fa-spin"></i>}
                </button>
                
            </form>;
        }

        return <div className="middle-box text-center loginscreen animated fadeInDown Login">
            <div>
                <div style={{display: 'none'}}>
                    <h1 className="logo-name" style={{marginLeft: "-140px"}}>{t('PRIMED')} </h1>
                </div>

                <div className="LogoContainer" style={{}}>
                    <PrimedLogo />
                </div>

            </div>

            {content}

            <div className="LoginInfoBox">
                <div className="row">
                    <div className="col-md-8 col-sm-8">
                        <p>Hai bisogno di aiuto?</p>
                        <p>Contattaci al <i className="fa fa-phone"></i> 099 / 5623969 <i className="fa fa-envelope"></i> webmaster@primed.it</p>
                    </div>
                    <div className="col-md-4 col-sm-4 text-right">
                        <a href="http://www.primed.it">www.primed.it</a>
                    </div>
                </div>
            
            </div>

        </div>;
        

    }
}

class ForgotPasswordFlow extends Component {
    constructor(props){
        super(props);

        this.state = {
            state: 0,
            email: '',
            loading: false,
            type: this.props.type,
            errorMessage: '',
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(se){
        se.preventDefault();

        if( this.state.loading )
            return;

        const msg = t('Invalid credential or wrong password');

        if( !this.state.email )
            return this.setState({errorMessage: msg });

        if( !this.state.email.length )
            return this.setState({errorMessage: msg });


        this.setState({loading: true,errorMessage:''});

        PriWebApp.fetch( 'user' , 'recover' , this.state , ( data ) => {

            this.setState({loading: false});

            if( data.status ){
                this.setState({
                    errorMessage: '',
                    state: 1,
                    email: data.data.email,
                });
            } else {
                this.setState({errorMessage: msg});
            }
        });
    }

    render(){
        if( this.state.state == 0 ){
            return <form className="m-t" action="">

                <div className="form-group">
                    <label className="form-label">Codice cliente / P.Iva / Email:</label>
                    <input type="email" value={this.state.email} className="form-control"
                    placeholder="" required="" 
                    onChange={se=> this.setState({email: se.target.value, errorMessage: ''})} />
                </div>

                <div className="form-group">
                    <p style={{color: 'red'}}>{this.state.errorMessage}</p>
                </div>

                <button type="submit" className="btn btn-primary block full-width m-b" onClick={this.onSubmit}>
                    {!this.state.loading?<span>{this.props.buttonLabel}</span>:<i className="fa fa-spinner fa-spin"></i>}
                </button>
                
            </form>;
        }

        if( this.state.state == 1 ){
            return <p className="RecoverPasswordMsg" >
                E' stata inviata una email all'indirizzo {this.state.email} ( Alcuni caratteri sono
                stati omessi ) {this.props.endLabel}.</p>;
        }
    }
}

class Login extends Component {

    constructor(props){
        super(props);

        Login.S_LOGIN = 1;
        Login.S_FORGOT_PASSWORD = 2;
        Login.S_REGISTER = 3;

        this.state = {
            email: '',
            password: '',
            loading: false,
            errorMessage: '',
            l:'',
            state: Login.S_LOGIN,
            showPassword: false,
            randVideo: Math.round( Math.random() * 3 ),
        };

        this.onLogin = this.onLogin.bind(this);
        this.onEyeClick = this.onEyeClick.bind(this);
    }

    componentDidMount(){
        document.body.className = "gray-bg";
        Route.onLocationChange( () => {
            this.setState({l:window.location.pathname});
        });
    }

    componentWillUnmount(){
        document.body.className = "";
    }

    onLogin(se){
        se.preventDefault();

        if( this.state.loading )
            return;

        const msg = t('Invalid credential or wrong password');

        if( !this.state.email || !this.state.password )
            return this.setState({errorMessage: msg });

        if( !this.state.email.length || !this.state.password.length )
            return this.setState({errorMessage: msg });


        this.setState({loading: true,errorMessage:''});

        PriWebApp.fetch( 'user' , 'login' , this.state , ( data ) => {

            this.setState({loading: false});

            if( data.status ){
                this.setState({errorMessage: ''});
                pwebs().dispatch( pweb().login( data.data.token ) );
                pwebs().dispatch( pweb().setUserData( data.data.user ) );
            } else {

                if( data.error_code == -2 ){
                    this.setState({errorMessage: 'Manutenzione dei sistemi in corso. Entro pochi minuti sarà possibile effettuare il login.'});
                } else {
                    this.setState({errorMessage: msg});
                }
            }
        });
    }

    onEyeClick( se ){
        this.setState({showPassword: !this.state.showPassword});
    }

    render(){

        const spanEyeStyle = {
            position: 'absolute',
            bottom: "10px",
            right: "15px",
            cursor: 'pointer',
        };

        let content = <form className="m-t" action="">

            <div className="form-group">
                <label className="form-label">Codice cliente / P.Iva / Email:</label>
                <input type="email" value={this.state.email} className="form-control"
                placeholder="Codice cliente o email" required=""
                onChange={se=> this.setState({email: se.target.value, errorMessage: ''})} />
            </div>

            <div className="form-group" style={{position: 'relative'}}>
                <label className="form-label">Password:</label>
                <input type={this.state.showPassword ? "text" : 'password'} value={this.state.password} className="form-control"
                placeholder="Password" required="" 
                onChange={se=> this.setState({password: se.target.value, errorMessage: ''})} />
                <span style={spanEyeStyle} onClick={this.onEyeClick}><i className={`fa ${this.state.showPassword?"fa-eye":"fa-eye-slash"}`}></i></span>
            </div>

            <div className="form-group">
                <p style={{color: 'red'}}>{this.state.errorMessage}</p>
            </div>

            <button type="submit" className="btn btn-primary block full-width m-b" onClick={this.onLogin}>
                {!this.state.loading?<span>Accedi</span>:<i className="fa fa-spinner fa-spin"></i>}
            </button>

            <p className="mt" style={{fontSize: '12pt',marginTop: '-30px',marginBottom: '30pt'}}>
                <Link href="/register">Registrati</Link></p>

            <p className="m-t"><Link href="/forgot">
                <small>Password dimenticata?</small>
            </Link></p>
            
        </form>;

        if( window.location.pathname.match( /^\/forgot\/?$/i ) )
            content = <ForgotPasswordFlow type="2"
            endLabel='con le istruzioni per effettuare il recupero password' buttonLabel='Recupera' />;

        if( window.location.pathname.match( /^\/register\/?$/i ) )
            content = <ForgotPasswordFlow type="1"
                endLabel='con le istruzioni per attivare il tuo account' buttonLabel='Registrati' />

        return <div className={"big-login-container"} >
            <div className="middle-box text-center loginscreen animated fadeInDown Login">
                <div>
                    <div style={{display: 'none'}}>
                        <h1 className="logo-name" style={{marginLeft: "-140px"}}>{t('PRIMED')} </h1>
                    </div>

                    <div className="LogoContainer" style={{}}>
                        <a href="/">
                            <PrimedLogo />
                        </a>
                    </div>

                    {content}

                </div>

                <div className="LoginInfoBox">
                    <div className="row">
                        <div className="col-md-8 col-sm-8">
                            <p>Hai bisogno di aiuto?</p>
                            <p>Contattaci al <i className="fa fa-phone"></i> 099 / 5623969 <i className="fa fa-envelope"></i> webmaster@primed.it</p>
                        </div>
                        <div className="col-md-4 col-sm-4 text-right">
                            <a href="http://www.primed.it">www.primed.it</a>
                        </div>
                    </div>

                </div>

            </div>
            <div className={"login-splash"}>
                <video autoPlay={true} loop={true} muted={true} id={"loginVideo"}>
                    <source src={`/video${this.state.randVideo}.mp4`} type="video/mp4" />
                </video>
            </div>
        </div>;
    }
}

class UserEdit extends Component {

    constructor(props){
        super(props);

        this.state = pwebs().getState();
        pwebs().subscribe( () => { 
            if( pwebs().getState().userData ) 
                this.setState( pwebs().getState().userData ); 
        } );

        this.profileSubmit = this.profileSubmit.bind(this);
    }

    componentDidMount(){
        pwebs().dispatch( pweb().setHeaderBreadcrumbs([
            {label: t('User profile'), url:'/user/edit'},
            {label: t('Edit profile'), url:'#'},
        ]));
    }

    profileSubmit(se){
        se.preventDefault();
        
        pweb().fetch( 'user' , 'edit' , this.state , data => {
            if( data.status )
                this.setState({order: data.data});
        } );
    }

    render(){

        return <div className="row">
            <div className="col-lg-8">
                <BoxContainer title={t("Change user profile")}>
                    <form  onSubmit={this.profileSubmit}>
                        <div className={"row"}>
                            <div className={"col-md-12 col-lg-4"}>
                                <FormInputText direction={FormInputContainer.HORIZONTAL} label={t('Customer code')} value={this.state.cda_ana} enable={false} />
                            </div>
                            <div className={"col-md-12 col-lg-8"}>
                                <FormInputText direction={FormInputContainer.HORIZONTAL} label={t('Business name')} value={this.state.des_ragsoc} enable={false}/>
                            </div>
                        </div>


                        <div className={"row"}>
                            <div className={"col-md-12"}>
                        
                            <div className="row">
                                <label className="control-label col-sm-12">{t('Address')}</label>
                                <div className="col-sm-12" style={{marginBottom: '15px'}}>
                                    <input className="form-control" value={this.state.des_indir}
                                           onChange={se=>this.setState({des_indir: se.target.value})} />
                                </div>
                                <div className="col-sm-2">
                                    <input className="form-control" value={this.state.sig_cap}
                                        onChange={se=>this.setState({sig_cap: se.target.value})} />
                                </div>
                                <div className="col-sm-6">
                                    <input className="form-control" value={this.state.des_loc}
                                        onChange={se=>this.setState({des_loc: se.target.value})}/>
                                </div>
                                <div className="col-sm-2">
                                    <input className="form-control" value={this.state.sig_prov}
                                        onChange={se=>this.setState({sig_prov: se.target.value})} />
                                </div>
                            </div>
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className={"col-md-12 col-lg-4"}>
                                <FormInputSelect direction={FormInputContainer.HORIZONTAL} label={t('Privacy agreement')} value={this.state.des_lib_1} values={{
                                    'Consenso totale' : t('Full agreement'),
                                    'Informativa inviata' : t('Agreement sent')
                                }} onChange={se => this.setState({des_lib_1: se.target.value})} />
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-md-12 col-lg-4"}>
                                <FormInputText direction={FormInputContainer.HORIZONTAL} label="Partita IVA" value={this.state.cda_piva} enable={false} />
                            </div>
                            <div className={"col-md-12 col-lg-4"}>
                                <FormInputText direction={FormInputContainer.HORIZONTAL} label="Codice fiscale" value={this.state.cda_cfisc} enable={false} />
                            </div>
                        </div>

                        <div className={"row"}>

                            <FormDashline />
                        </div>

                        <div className={"row"}>
                            <div className="col-lg-6 col-sm-12">
                                <FormInputText direction={FormInputContainer.HORIZONTAL} label="Email 1" value={this.state.des_email}
                                               onChange={se=>this.setState({des_email: se.target.value})} />

                                <FormInputText direction={FormInputContainer.HORIZONTAL} label="Pec" value={this.state.email_pec}
                                               onChange={se=>this.setState({email_pec: se.target.value})} />

                                <FormInputText direction={FormInputContainer.HORIZONTAL} label={t('Mobile phone')} value={this.state.des_tel} />

                                <FormInputText direction={FormInputContainer.HORIZONTAL} label={t('Fax')} value={this.state.des_fax} />

                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <FormInputText direction={FormInputContainer.HORIZONTAL} label="Email 2" value={this.state.des_email1}
                                               onChange={se=>this.setState({des_email1: se.target.value})} />

                                <FormInputText direction={FormInputContainer.HORIZONTAL} label={t('Telephone 1')} value={this.state.des_tel}
                                               onChange={se=>{this.setState({des_tel:se.target.value})}} />

                                <FormInputText direction={FormInputContainer.HORIZONTAL} label={t('Web site')} value={this.state.des_fax} />
                            </div>

                        </div>

                        <div className={"row"}>
                            <FormButtons />
                        </div>

                        <div className={"row"}>

                            <FormDashline />
                        </div>

                    </form>
                </BoxContainer>


                {this.state.recapti && <BoxContainer title={t('Recapiti')}>

                    <form onSubmit={this.profileSubmit} >

                        {this.state.recapti.map( (recapito,index) => {
                            return <React.Fragment>
                                <div className={"row"}>
                                    <div className={"col-md-6 col-lg-4"}>
                                        <FormInputText direction={FormInputContainer.HORIZONTAL}
                                           label="Tipo" value={recapito.DES_TIPO_RECAPITO}
                                           onChange={se=>this.setState({DES_TIPO_RECAPITO: se.target.value})} />
                                    </div>
                                </div>

                                <div className={"row"}>
                                    <div className={"col-md-4"}>
                                        <FormInputText direction={FormInputContainer.HORIZONTAL}
                                           label="Email" value={recapito.DES_EMAIL}
                                           onChange={se=>this.setState({DES_EMAIL: se.target.value})} />
                                    </div>
                                    <div className={"col-md-4"}>
                                        <FormInputText direction={FormInputContainer.HORIZONTAL}
                                           label="Telefono" value={recapito.DES_TEL}
                                           onChange={se=>this.setState({DES_EMAIL: se.target.value})} />
                                    </div>
                                    <div className={"col-md-4"}>
                                        <FormInputText direction={FormInputContainer.HORIZONTAL}
                                           label="Whatsapp" value={recapito.DES_WAPP}
                                           onChange={se=>this.setState({DES_WAPP: se.target.value})} />
                                    </div>
                                </div>

                                <div className="row">
                                    <FormDashline />
                                </div>

                            </React.Fragment>;
                        })}

                        <div className="row">
                            <FormButtons />
                        </div>

                        <FormDashline />

                    </form>


                </BoxContainer> }


            </div>
            <div className="col-lg-4" id="settings">

                <BoxContainer title={t("Logo")}>
                    <form className="hor">
                        <FormInputImage src={`${window.location.protocol}//${window.location.hostname}/api1/user/logo/${this.state.userData.logo_token}`} />l
                    </form>
                </BoxContainer>

                <BoxContainer title={t("Position")}>
                    <form onSubmit={this.onSubmit}>
                        <FormInputMap label={t('')} 
                            direction={FormInputContainer.HORIZONTAL}
                            lat={this.state.latitudine ? this.state.latitudine.replace(/,/,'.'): 0} 
                            lng={this.state.longitudine ? this.state.longitudine.replace(/,/,'.') : 0}
                            onChange={(lat,lng) => {this.setState({
                                latitudine: lat.toString().replace( /\./ , ',' ), 
                                longitudine: lng.toString().replace( /\./ , ',' ), 
                            })}} />

                        <FormButtons direction={FormInputContainer.HORIZONTAL} />       
                    </form>
                </BoxContainer>

                <BoxContainer title={t("Settings")}>
                    <form className="" onSubmit={this.onProfileSubmit}>

                        <FormInputSelect label={t('Information confirmation')} direction={FormInputContainer.HORIZONTAL}>
                            <FormInputSelectOption label={t('Email')} />
                        </FormInputSelect>

                        <FormInputSelect label={t('Orders confirm')} direction={FormInputContainer.HORIZONTAL}>
                            <FormInputSelectOption label={t('Not required')} />
                            <FormInputSelectOption label={t('Yes, without block orders')} />
                            <FormInputSelectOption label={t('Yes, block orders')} />
                        </FormInputSelect>

                        <FormInputSelect label={t('DDT Send')} direction={FormInputContainer.HORIZONTAL}>
                            <FormInputSelectOption label={t('Fax')} />
                            <FormInputSelectOption label={t('Email')} />
                            <FormInputSelectOption label={t('-')} />
                        </FormInputSelect>


                        <FormInputSelect label={t('Invoice Send')} direction={FormInputContainer.HORIZONTAL}>
                            <FormInputSelectOption label={t('Fax')} />
                            <FormInputSelectOption label={t('Email')} />
                            <FormInputSelectOption label={t('Physical mail box')} />
                        </FormInputSelect>

                        <FormDashline />

                        <FormButtons direction={FormInputContainer.HORIZONTAL} />

                    </form>
                </BoxContainer>
                <BoxContainer title={t("Change password")}>
                    <form className="">
                        <FormInputPassword label="Password attuale" value="" direction={FormInputContainer.HORIZONTAL} />
                        <FormInputPassword label="Nuova password" value="" direction={FormInputContainer.HORIZONTAL} />
                        <FormInputPassword label="Conferma nuova password" value="" direction={FormInputContainer.HORIZONTAL} />

                        <FormDashline />

                        <FormButtons saveLabel="Cambia password"  direction={FormInputContainer.HORIZONTAL} />

                    </form>
                </BoxContainer>
            </div>
        </div>;
    }
}

class ProfileData extends Component {
    render(){
        const udata = pwebs().getState().userData;

        return <div>
            <h4>{udata.des_ragsoc}</h4>
            <p>{udata.des_indir}</p>
            <p>{udata.sig_cap} {udata.des_loc} ({udata.sig_prov})</p>
            <p><i className="fa fa-phone"></i> {udata.des_tel} 
            <i style={{marginLeft: '10px'}} className="fa fa-envelope"></i> {udata.des_email}</p>
        </div>;
    }
}

class ShippingAddresses extends Component {

    constructor(props){
        super(props);

        this.state = pwebs().getState();
        pwebs().subscribe( () => { 
            if( pwebs().getState().userData ) 
                this.setState( pwebs().getState().userData ); 
        } );

    }

    componentDidMount(){
        pwebs().dispatch( pweb().setHeaderBreadcrumbs([
            {label: 'Utente ' + pwebs().getState().userData.cda_ana , url:'/user/edit'},
            {label: t('Shipping addresses'), url:'#'},
        ]));
    }

    render(){

        return <BoxContainer title={t('Shipping addresses')} id="shipping_address">
            <div className="row">
                {Object.keys(this.state.userData.shipping_addresses).map( key => {
                    const address = this.state.userData.shipping_addresses[key];
                    return <div className="col-sm-6 col-md-6 col-lg-4">
                        <ShippingAddress cdn_dm={address.CDN_DM} />
                        <FormDashline />
                        </div>;})}
            </div>
        </BoxContainer>
    }
}


class ShippingAddress extends Component {

    constructor(props){
        super(props);

        this.state = pwebs().getState();
        pwebs().subscribe( () => { 
            if( pwebs().getState().userData ) 
                this.setState( pwebs().getState().userData ); 
        } );

    }

    render(){

        if( !this.props.cdn_dm )
            return <p>ERROR</p>;

        let dm = false;

        Object.keys(this.state.userData.shipping_addresses).map( _dm => {
            if( _dm == this.props.cdn_dm ) dm = this.state.userData.shipping_addresses[_dm];
            return null;
        });

        return <div className="ShippingAddress"> 

            <div className="row">
                
                <div className="col-sm-12">

                    <h3><strong>{dm.DES_DM}</strong></h3>

                    <p><i className="fa fa-map-marker fa-fw"></i> {dm.DES_INDIR}</p>
                </div>

                {dm.DES_TEL && <div className="col-sm-12 col-lg-6">
                    <p>
                       <i className="fa fa-phone fa-fw"></i> {dm.DES_TEL}
                    </p>
                </div> }

                {dm.DES_EMAIL && <div className="col-sm-12 col-lg-6">
                    <p>
                       <i className="fa fa-envelope fa-fw"></i> {dm.DES_EMAIL}
                    </p>
                </div> }

                {dm.DES_TELEX && <div className="col-sm-12 col-lg-6">
                    <p>
                       <i className="fa fa-mobile fa-fw"></i> {dm.DES_TELEX}
                    </p>
                </div> }

                {dm.DES_WWW && <div className="col-sm-12 col-lg-6">
                    <p>
                       <i className="fa fa-globe fa-fw"></i> {dm.DES_WWW}
                    </p>
                </div> }

            </div>

        </div>;
    }
}


class UserEditContact extends Component {
    constructor(props){
        super(props);

        this.state = pwebs().getState();

        pwebs().subscribe( () => { 
            if( pwebs().getState().userData ) 
                this.setState( pwebs().getState().userData ); 
        } );

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount(){

        pwebs().dispatch( pweb().setHeaderBreadcrumbs([
            {label: t('User profile'), url:'/user/edit'},
            {label: t('Edit contact'), url:'#'},
            {label: '[NAME]', url:'#'},
        ]));

        const urls = window.location.pathname.split('/');
    }

    onSubmit(se){
        se.preventDefault();
    }

    render(){
        return <BoxContainer title={t('Edit contact information')}>

            <form className="form-horizontal" onSubmit={this.onSubmit}>

                <FormInputText label={t('Name')} value=""  />

                <FormDashline />

                <FormInputText label={t('Telephone')} value=""  />
                <FormInputText label={t('Gsm')} value=""  />
                <FormInputText label={t('Fax')} value=""  />
                <FormInputText label={t('Intern')} value=""  />
                <FormInputText label={t('Email')} value=""  />

                <FormDashline />

                <FormButtons onCancel={()=>{Route.pushLocation('/user/edit')}}
                    onSave={this.onSubmit} />

            </form>

        </BoxContainer>;

        return <div>Edit contact</div>;
    }
}

export { Login , ShippingAddress , ShippingAddresses , UserEdit , 
    UserEditContact ,
    GeneratePasswordFlow, ProfileData };