
import { createStore } from 'redux';
import superagent from 'superagent';

if( !Array.from ) {
    Array.from = (function () {
        var toStr = Object.prototype.toString;
        var isCallable = function (fn) {
          return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
        };
        var toInteger = function (value) {
          var number = Number(value);
          if (isNaN(number)) { return 0; }
          if (number === 0 || !isFinite(number)) { return number; }
          return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
        };
        var maxSafeInteger = Math.pow(2, 53) - 1;
        var toLength = function (value) {
          var len = toInteger(value);
          return Math.min(Math.max(len, 0), maxSafeInteger);
        };
    
        // The length property of the from method is 1.
        return function from(arrayLike/*, mapFn, thisArg */) {
          // 1. Let C be the this value.
          var C = this;
    
          // 2. Let items be ToObject(arrayLike).
          var items = Object(arrayLike);
    
          // 3. ReturnIfAbrupt(items).
          if (arrayLike == null) {
            throw new TypeError("Array.from requires an array-like object - not null or undefined");
          }
    
          // 4. If mapfn is undefined, then let mapping be false.
          var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
          var T;
          if (typeof mapFn !== 'undefined') {
            // 5. else
            // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
            if (!isCallable(mapFn)) {
              throw new TypeError('Array.from: when provided, the second argument must be a function');
            }
    
            // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
            if (arguments.length > 2) {
              T = arguments[2];
            }
          }
    
          // 10. Let lenValue be Get(items, "length").
          // 11. Let len be ToLength(lenValue).
          var len = toLength(items.length);
    
          // 13. If IsConstructor(C) is true, then
          // 13. a. Let A be the result of calling the [[Construct]] internal method of C with an argument list containing the single item len.
          // 14. a. Else, Let A be ArrayCreate(len).
          var A = isCallable(C) ? Object(new C(len)) : new Array(len);
    
          // 16. Let k be 0.
          var k = 0;
          // 17. Repeat, while k < len… (also steps a - h)
          var kValue;
          while (k < len) {
            kValue = items[k];
            if (mapFn) {
              A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
            } else {
              A[k] = kValue;
            }
            k += 1;
          }
          // 18. Let putStatus be Put(A, "length", len, true).
          A.length = len;
          // 20. Return A.
          return A;
        };
      }());
}

class Globals {

    
    static addEvent( object , eventName ){

        if( typeof( object ) !== 'object' && typeof( object ) !== 'function' )
            return false;

        const onEventName = 'on' + eventName;
        const listenersEventName = '__' + eventName + '_listeners';

        if( typeof( object[onEventName] ) !== 'undefined' || typeof( object[listenersEventName] ) !== 'undefined' )
            return false;

        object[listenersEventName] = [];

        object[onEventName] = function( cb ){
            if( typeof( cb ) !== 'function' )
                return () => {};

            object[listenersEventName].push( cb );

            const currentLength = object[listenersEventName].length;

            return () => {
                console.log( `Removing event listner ${currentLength-1}` );
                object[listenersEventName].splice( currentLength-1 , 1 );
            };
        }

        if( typeof( object['trigger'+eventName] ) === 'undefined' ){
            object['trigger'+eventName] = function( ){
                for(let i=0; i < object[listenersEventName].length;i++)
                    object[listenersEventName][i].apply( object , arguments );
            }
        }

    }

}

const LocalCache = {

    set: function( name , value, timeoutInSeconds = false ){
        localStorage.setItem( name , JSON.stringify( value ) );
        if( timeoutInSeconds )
            localStorage.setItem( 'timeout_' + name , (new Date()).getTime() + (timeoutInSeconds*1000) );
    },

    get: function( name ){
        let savedValue = localStorage.getItem( name );
        if( savedValue ){
            try {
                savedValue = JSON.parse( savedValue );
            } catch( e ){ savedValue = false }
        }

        const timeout = localStorage.getItem( 'timeout_' + name );
        if( timeout ){
            if( (new Date()).getTime() - timeout > 0 )
                savedValue = false;
        }

        return savedValue;
    },

    clear: function( name ){
        localStorage.removeItem( name );
        localStorage.removeItem( 'timeout_' + name );
    },

    groupSet: function( groupName , value ){
        return LocalCache.set( 'cacheGroups_' + groupName , value );
    },

    groupGet: function( groupName ){
        let g = LocalCache.get( 'cacheGroups_' + groupName );
        if( typeof( g ) !== 'object' || g === null )
            return [];
        return g;
    },

    groupAppendName: function( groupName , name ){
        if( typeof( name ) !== 'string' || typeof( groupName ) !== 'string' ) 
            return;

        let g = LocalCache.groupGet( groupName );
        if( g.indexOf( name ) === -1 )
            g.push( name );

        this.groupSet( groupName , g );
    },

    groupClear: function( groupName ){
        let g = LocalCache.groupGet( groupName );
        g.map( item => {
            LocalCache.clear( item );
        });

        LocalCache.groupSet( groupName , [] );
    }
}

const PriWebApp = {

    A_SET_LOADER_VISIBILITY: 'A_SET_LOADER_VISIBILITY',

    showLoader: function(){
        return { type: PriWebApp.A_SET_LOADER_VISIBILITY , visibility: true };
    },

    hideLoader: function(){
        return { type: PriWebApp.A_SET_LOADER_VISIBILITY , visibility: false };
    },

    A_LOGIN: 'A_LOGIN',
    login: function( token ){

        // In case of logout / login , clean up cache information
        localStorage.clear();
        
        
        return { type: PriWebApp.A_LOGIN , token: token };
    },

    A_SET_USER_DATA: 'A_SET_USER_DATA',
    setUserData: function( user_data ){

        try {
            let keys = Object.keys( user_data.shipping_addresses ).slice( 0 , 32 );

            user_data.shipping_addresses = Object.fromEntries( keys.map( k => {
                return [ k , user_data.shipping_addresses[k]];
            }));

        } catch ( e ){
            console.log( e );
        }

        return { type: PriWebApp.A_SET_USER_DATA , user_data: user_data };
    },

    A_SET_HEADER_BREADCRUMBS: 'A_SET_HEADER_BREADCRUMBS',

    setHeaderBreadcrumbs( breadcrumbsArray ){
        if( breadcrumbsArray ){
            if( breadcrumbsArray.length > 0 ){
                document.title = 'Primed | ' + breadcrumbsArray.map( item => { return item.label; }).join(' | ');
            }
        }
        
        return { type: PriWebApp.A_SET_HEADER_BREADCRUMBS , breadcrumbsArray: breadcrumbsArray };
    },

    A_CLOSE_ALERT: 'A_CLOSE_ALERT',
    closeAlert(){
        return { type: PriWebApp.A_CLOSE_ALERT , sweetAlert:{ visible: false } };
    },

    A_SHOW_ALERT: 'A_SHOW_ALERT',
    showAlert( title , content , buttons ){
        return { type: PriWebApp.A_SHOW_ALERT, sweetAlert: {
            visible: true,
            title: title,
            content: content,
            buttons: buttons
        }};
    },

    A_STARTUP_DONE: 'A_STARTUP_DONE',
    startupDone: function(){
        return {type: PriWebApp.A_STARTUP_DONE };
    },

    A_SET_DOWNLOADS: 'A_SET_DOWNLOADS',
    setDownloads: function( downloads ){
        return { type: this.A_SET_DOWNLOADS , downloads: downloads };
    },

    A_SET_PRODUCTS: 'A_SET_PRODUCTS',
    setProducts: function( products ){
        return { type: this.A_SET_PRODUCTS , products: products };
    },

    A_SEARCH_VISIBILITY: 'A_SEARCH_VISIBILITY',
    setSearchVisibility: function( trueOrFalse ){
        return { type: this.A_SEARCH_VISIBILITY , visibility: trueOrFalse == true };
    },

    /**
     * BASICS FLOW FUNCTION
     */

    __save_string: '__primed_support_data_1_0_1',

    initialState: {
        loaderVisibility: false,
        startupDone: false,
        authorizationKey: false,
        userData: false,
        breadcrumbsArray: [],
        sweetAlert: {
            visible: false,
            title: 'Test message',
            content: 'Welcome to our test message',
            buttons: []
        },
        searchVisible: true,
        downloads: {},
        products: [],
        productsColorCatalog: [],
    },

    reducer: function( state = PriWebApp.initialState , action ){

        if( action.type === PriWebApp.A_SEARCH_VISIBILITY )
            return { ...state , searchVisible: action.visibility };

        if( action.type === PriWebApp.A_SET_LOADER_VISIBILITY )
            return { ...state , loaderVisibility: action.visibility };

        if( action.type === PriWebApp.A_LOGIN )
            return { ...state , authorizationKey: action.token };
            
        if( action.type === PriWebApp.A_SET_HEADER_BREADCRUMBS )
            return { ...state, breadcrumbsArray: action.breadcrumbsArray };

        if( action.type === PriWebApp.A_SET_USER_DATA )
            return { ...state, userData: action.user_data };

        if( action.type === PriWebApp.A_STARTUP_DONE )
            return { ...state, startupDone: true };

        if( action.type === PriWebApp.A_SHOW_ALERT || action.type === PriWebApp.A_CLOSE_ALERT )
            return { ...state, sweetAlert: action.sweetAlert };

        if( action.type === PriWebApp.A_SET_DOWNLOADS )
            return { ...state, downloads: action.downloads };

        if( action.type === PriWebApp.A_SET_PRODUCTS && action.products && action.products.map ){
            let products = action.products.map( product => {
                product.options = product.options.map( option => {

                    let groupCatalog = {};

                    option.values.map( value => {
                        if( !groupCatalog[value.group] )
                            groupCatalog[value.group] = [];

                        return groupCatalog[value.group].push( value );
                    });

                    return { ...option , catalogByGroup: groupCatalog };
                });

                return product;
            });

            return { ...state, products: products };
        }

        return state;
    },

    store: false,

    updateDownloadList: function(){
        if( !PriWebApp.updatingDownloadList ){
            PriWebApp.updatingDownloadList = true;
            PriWebApp.fetch( 'download' , 'list' , msg => {
                PriWebApp.updatingDownloadList = false;
                PriWebApp.store.dispatch( PriWebApp.setDownloads( msg.data ) );
            });
        }
    },

    init: function(){

        
        let savedData = localStorage.getItem( PriWebApp.__save_string );
        if( savedData ){
            try {
                savedData = JSON.parse( savedData );
                
                PriWebApp.initialState = {
                    ...PriWebApp.initialState,
                    ...savedData
                };

            } catch( e ){}
        }

        PriWebApp.store = createStore( PriWebApp.reducer );

        PriWebApp.requestingProducts = false;

        /**
         * Ci sono delle situazione ( ad esempio dopo un logout -> login )
         * in cui i dati vengno invalidati e non vengono ricaricate
         * le informazione di prodotti e download
         */
        setInterval( ()=>{
            const state = PriWebApp.store.getState();

            if( state.products.length < 1 && state.authorizationKey && PriWebApp.requestingProducts == false && state.userData.quotation_enable ){
                PriWebApp.requestingProducts = true;
                PriWebApp.fetch( 'quotations' , 'products' , 'list' , msg => {
                    PriWebApp.requestingProducts = false;
                    PriWebApp.store.dispatch( PriWebApp.setProducts( msg.data ) );
                });
            }

            if( state.downloads ){
                if( state.userData && !Object.keys( state.downloads ).length ){
                    PriWebApp.updateDownloadList();
                }
            }

        } , 3000 );

        PriWebApp.store.subscribe( () => {
            let state = PriWebApp.store.getState();

            let stateToSave = {
                authorizationKey: state.authorizationKey,
                userData: state.userData
            };

            /*if( state.downloads ){
                if( state.userData && !Object.keys( state.downloads ).length ){
                    PriWebApp.updateDownloadList();
                }
            }*/

            localStorage.setItem( PriWebApp.__save_string , JSON.stringify( stateToSave ) );

        });

        if( PriWebApp.initialState.authorizationKey ){

            PriWebApp.fetch( 'quotations' , 'products' , 'list' , msg => {
                PriWebApp.store.dispatch( PriWebApp.setProducts( msg.data ) );
            });

            PriWebApp.fetch( 'user' , 'checkAuthorizationKey' , msg => {
                if( !msg.status )
                    PriWebApp.store.dispatch( PriWebApp.login( false ) );
                else {
                    PriWebApp.store.dispatch( PriWebApp.setUserData( msg.data.userData ) );
                }
                
                PriWebApp.store.dispatch( PriWebApp.startupDone() );
            } );
        } else {
            PriWebApp.store.dispatch( PriWebApp.startupDone() );
        }
    },
    
    apiUrl(){
        return `${window.location.protocol}//${window.location.hostname}/api1`;
    },

    cacheGet( name ){
        return LocalCache.get( name );
    },

    cacheSet( name , value, timeoutInSeconds = false ){
        return LocalCache.set( name , value , timeoutInSeconds );
    },

    passiveCacheFetch( /* ... */ ){

        const name = encodeURI( Array.from( arguments ).map( arg => {
            return JSON.stringify( arg );
        } ).join('-') );

        let savedValue = this.cacheGet( PriWebApp.__save_string + name );

        const lastArgument = arguments[arguments.length-1];

        this.fetch.apply( this , Array.from(arguments).slice(0 , arguments.length-1).concat( [ msg => {
            if( msg.status ){
                //localStorage.setItem( PriWebApp.__save_string + name , JSON.stringify( msg ) );
                this.cacheSet( PriWebApp.__save_string + name , msg , 60 * 5 );
                LocalCache.groupAppendName( 'fetch' , PriWebApp.__save_string + name );
            } else {}

            if( !savedValue && typeof( lastArgument ) === 'function' ){
                lastArgument( msg );
            }
        } ] ) );

        if( savedValue ){
            if( typeof( lastArgument ) === 'function' ){
                lastArgument( savedValue );
            }
        }
    },

    testConnnection(){
        let url = this.apiUrl();
        url += '/systemTest';
        url += '?t=' + (new Date()).getTime();
    },

    getProduct( name ){
        let p = false;

        try {
            for( let i = 0; i < PriWebApp.store.getState().products.length; i++ ){
                const ip = PriWebApp.store.getState().products[i];
                if( name.toLowerCase().trim() == ip.cda_art.toLowerCase().trim() )
                    p = ip;
            }
        } catch ( e ){
            console.error( e );
        }

        return p;
    },

    fetch(){

        const base_url = this.apiUrl();

        let url = base_url;

        let cb = false;

        let postData = false;

        for( let i = 0; i < arguments.length ; i++ ){

            if( typeof ( arguments[i] ) === 'object' ){
                postData = arguments[i];
                continue;
            }

            if( typeof ( arguments[i] ) === 'function' ){
                cb = arguments[i];
                break;
            }

            url += '/' + encodeURIComponent( arguments[i] );
        }

        url += '?t=' + (new Date()).getTime();

        //console.debug( 'Fetching at url ' , url , arguments );

        let request = false;

        if( !postData ){
            request = superagent.get( url )
        } else {
            request = superagent.post( url );

            let fileLoaded = false;

            for( var key in postData ){
                if( !postData[key] )
                    continue;
                    
                if( postData[key].size && postData[key].name ){
                    // IS FILE
                    const file = postData[key];
                    fileLoaded = true;
                    request = request.attach( file.name, file );
                    console.log( 'Attaching file to post' );
                    delete postData[key];
                }
            }

            if( !fileLoaded ){
                request = request.set('Content-Type', 'application/json');
                request = request.send( postData );
            }
        }

        if( PriWebApp.store.getState().authorizationKey ){
            request.set( 'Access-Token', PriWebApp.store.getState().authorizationKey );
        }

        request.end( (err,response) => {
            if( !cb )
                return;

            if( err ){
                return cb( {
                    status: false,
                    message: 'Error',
                    error_code: -2
                } );
            }

            //console.debug( 'Received: ' , response );

            cb( response.body );

        } );

        console.log( [ url , arguments ] );

        return request;
    }

};

function logObjectsDiff( from , to , name = 'Obj diff: ' ){
    let stateChanged = {};

    if( !to || !from )
        return;
    
    Object.keys( to ).concat( Object.keys( from ) ).map( sk => {
        if( to[sk] != from[sk] ){
            stateChanged[sk] = {
                from: from[sk],
                to: to[sk]
            }
        }
    });

    if( Object.keys( stateChanged ).length > 0 )
        console.log( name , stateChanged );
}

function logComponentDiff( state, prevState , props , prevProps , name = 'Unamed ' ) {
    logObjectsDiff( prevState , state , name );
    logObjectsDiff( prevProps , props , name );
}

export { Globals , PriWebApp , LocalCache, logComponentDiff };